import { Typography } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import Spinner from "./../../../../Element/Spinner/Spinner";
import "./event.css";
import { Paginer } from "./Paginer/Paginer";
import { HallEvent } from "./../../../../../Type";
import { generateQrCode } from "../../../../Utilities/Various";

export function Event() {
  const [isLoading, setIsLoading] = useState<boolean>(true); // condition to display the spinner
  const [listEvent, setListEvent] = useState<HallEvent[]>([]); // list of all hall event
  const numberOfEventPerPage = Math.floor(window.innerHeight / 151 - 1); // max number of event displayed per page
  const [page, setPage] = useState<number>(0); // page number displayed
  const eventPageManager = () =>
    setPage(pageManager(page, listEvent.length, numberOfEventPerPage)); // event function to manage the page number (page++ or 0)

  useEffect(() => {
    if (isLoading) {
      getEvent()
        .then((response) => {
          listEventSeter(response).then(() => setListEvent(response));
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false));
    } else {
      setTimeout(() => {
        setIsLoading(true);
        getEvent()
          .then((response) => {
            listEventSeter(response).then(() => setListEvent(response));
          })
          .catch((e) => console.log(e))
          .finally(() => setIsLoading(false));
      }, 1000 * 60 * 10);
    }
  }, [listEvent]);

  let mainRendering: ReactElement = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f28f22",

        width: "490px", // 430
      }}
    >
      <div
        style={{
          paddingBottom: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography marginTop={"20px"} variant="h4" component="div">
          Bienvenue chez Altyor !
        </Typography>
      </div>

      <div
        className="infoEvent"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flexGrow: "1",
        }}
      >
        <Paginer
          events={listEvent}
          page={page}
          numberOfEventPerPage={numberOfEventPerPage}
          eventPageManager={eventPageManager}
        ></Paginer>
      </div>
    </div>
  );

  return isLoading ? (
    <Spinner />
  ) : listEvent.length === 0 ? (
    <></>
  ) : (
    mainRendering
  );
}

/**
 * Fetch the event of the day
 * @returns HallEvent[] object array
 */
async function getEvent(): Promise<HallEvent[]> {
  let headers = new Headers();
  headers.append("x-api-key", process.env.REACT_APP_ApiGetWay!);
  let siteID: string = encodeURI(process.env.REACT_APP_SiteID!);
  let listID: string = encodeURI(process.env.REACT_APP_ListID!);

  let url =
    process.env.REACT_APP_BackEndPoint +
    "/list?siteID=" +
    siteID +
    "&listID=" +
    listID;
  
  console.log("Fetching data from:", url); // Log the URL being fetched
  
  let response: any = await fetch(url, {
    headers: headers,
  }).catch((error: Error) => {
    console.log("Error fetching data:", error); // Log any errors that occur during fetch
  });

  let json = await response.json();

  console.log("Received data:", json); // Log the data received from the fetch

  return json;
}


/**
 * Calculate the new page number (page++ or 0)
 * @param page actual page
 * @param listEventLength length of the array of event
 * @param numberOfEventPerPage number of displayable event per page depending of the heigth of the browser screen
 * @returns new number of the page
 */
function pageManager(
  page: number,
  listEventLength: number,
  numberOfEventPerPage: number
): number {
  let buffer = -1;
  if (page + 1 >= listEventLength / numberOfEventPerPage) {
    buffer = 0;
  } else {
    buffer = page + 1;
  }
  return buffer;
}

async function listEventSeter(listEvent: HallEvent[]) {
  let promiseArray: any[] = [];
  listEvent.forEach(async (element: HallEvent) => {
    promiseArray.push(
      new Promise(async (resolve, reject) => {
        element.DisplayNameofVisitors = element.Title;
        element.QrCode = await generateQrCode(
          "000000",
          "f28f22",
          process.env.REACT_APP_FrontEndPoint +
            "/list?id=" +
            encodeURI(element.id)
        );
        resolve("");
      })
    );
  });

  await Promise.all(promiseArray);
}
